// export const topics = [

// ];

import { HTMLTopic } from "./tables/HTML/HTMLTopic";
import { CSSTopic } from "./tables/CSS/CSSTopic";
import { LayoutTopic } from "./tables/CSS/LayoutTopic";
import { ReactTopic } from "./tables/React/ReactTopic";
import { MemoryTopic } from "./tables/React/MemoryTopic";
import { PassDataTopic } from "./tables/React/PassDataTopic";
import { PropsTopic } from "./tables/React/PropsTopic";
import { RefTopic } from "./tables/React/RefTopic";
import { StateTopic } from "./tables/React/StateTopic";
export const topics = [
  ReactTopic,
  // ComponentTopic,
  MemoryTopic,
  StateTopic,
  RefTopic,
  PassDataTopic,
  // PropsTopic,
  // HTMLTopic,
  CSSTopic,
  LayoutTopic,
];
